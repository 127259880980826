//全局业务方法
import store from "@/store";
//发薪单-获取支付方式
export function getPayMethod(id){
    return store.state.global.payWayList.find(item => String(item.id) === id)?.label || '--'
}
//发薪单-获取领取状态
export function getStatus(id){
    return store.state.global.receiveStatusList.find(item=>String(item.id)===id)?.label||'--';
}
//发薪单-获取领取方式
export function getReceiveMethod(id){
    if(id==='4'){
        id='1'
    }else if(id==='5'){
        id='2'
    }
    return store.state.global.receiveWayList.find(item=>String(item.id)===id)?.label||'--';
}
import Vue from 'vue'
import Vuex from 'vuex'
import router from "../router";
import {resetRouter} from '../router/initRoutes'
import iApi from '../util/HFApi'
import city from '../util/json/city.json'
import routerJson from "@/router/index.json"
city.shift()
import {Message} from "element-ui";
Vue.use(Vuex)
import globalStore from "@/store/modules/global";
export default new Vuex.Store({
    state: {
        Home: {
            EChartsFlag: true
        },
        codeTime: 0,//验证码倒计时
        //当前分页 导出表格时重置使用
        paging: {
            size: 20,
            no: 1
        },
        //退出前的路由
        beforeRouter: '/',
        //权限提示 true代表有权访问
        author: true,
        //权限提示 文字
        authorTxt: '',
        //联系电话
        linkTel: '400-082-6916',
        city
    },
    getters: {},
    mutations: {
        //判断权限标记
        authorDetermine(state, data) {
            state.author = data.flag
            state.authorTxt = data.txt
        },
        //登录总功能树处理
        allRouterHandle(state, data) {
            if (localStorage.getItem('YM_THREE_HANDLE')) return
            const arr = iApi.getRouter(data)
            localStorage.setItem('YM_THREE_HANDLE', JSON.stringify(arr))//代表已经处理过功能树json数据 json字符串数据转换为json对象数据
        },
        //登录权限树处理
        userRouterHandle(state, data) {
            if (localStorage.getItem('YM_USER_THREE_HANDLE')) return
            const arr = iApi.getRouter(data)
            if (arr&&arr.length) {
                arr.forEach(item => {
                    router.addRoute(item.name, item)
                })
            } else {
                Message.error('账户当前无权限')
            }
        },
        //退出登录
        quitLogin(state) {
            state.author = true //取消授权提示界面
            resetRouter()//重置路由信息--清除旧路由
            localStorage.removeItem('YM_INFO')
            sessionStorage.removeItem('YM_TOKEN')
            localStorage.removeItem('YM_USER_THREE')
            localStorage.removeItem('YM_THREE_HANDLE')
            localStorage.removeItem('YM_THREE')
            localStorage.removeItem('YM_USER_THREE_HANDLE')
            localStorage.removeItem('YM_DICT')
            // sessionStorage.removeItem('YM_LOGIN_PW_CHECK')//清除”设置密码弹窗是否弹出“标记
            router.push('/login')
        },
        //退出时的路由
        quitRouter(state) {
            state.beforeRouter = router.app._route.fullPath
        },
        //验证码倒计时
        codeTimeHandel(state, v) {
            state.codeTime = v
            let intTime = setInterval(() => {
                state.codeTime--
                if (!state.codeTime) {
                    return clearInterval(intTime)
                }
            }, 1000)
        },
        //记录table分页公共状态 场景：导出表格
        pagingState(state, v) {
            state.paging = v
        },
    },
    actions: {},
    modules: {
        global:globalStore
    }
})

const getDictList = localStorage.getItem('YM_DICT')?JSON.parse(localStorage.getItem('YM_DICT')):null;
import Vue from 'vue'
const that = Vue.prototype;
const globalStore = {
    state: () => ({
        //合同类型
        concatType:[{label: '劳务合同', id: '1'},{label: '劳动合同', id: '2'},{label: '用工协议', id: '3'},{label: '离职合同',id: '4'}],
        //收款方式
        recPayList:[{label:'支付宝',id:1},{label:'微信',id:2}],
        //转账状态
        traAccList:[{label:'待转账',id:0,color:'#FF8D1AFF'},{label:'转账成功',id:1,color:'#00BF4DFF'},
            {label:'转账失败',id:2,color:'#DB0000FF'},{label:'转账中',id:3,color:'#FF8D1AFF'}],
        //发薪单类型
        payslipTypeList: [{label: '工资', id: 1,color:'#00BAADFF'}, {label: '预支', id: 2,color:'#FF5733FF'} ,{label: '日结', id:3,color:''}],
        //支付方式 ,{label:'直接支付-银行卡',id:3},
        payWayList:[{label:'直接支付-微信',id:2},{label:'直接支付-支付宝',id:1},{label:'支付到钱包-微信',id:5},{label:'支付到钱包-支付宝',id:4}],
        //支付方式分类
        payWayTypeList:[{label:'直接支付',id:1},{label:'支付至用户钱包',id:2}],
        //领取状态
        receiveStatusList:[{label:'未领取',id:4,color:'#FF8D1A'},{label:'已领取',id:1,color:'#00BF4DFF'},
            {label:'停止发薪',id:5,color:'#FF2F00'},{label:'领取失败',id:2,color:'#DB0000FF'}],
        //领取/转账总状态
        payStatusList:[{label:'未领取',id:4,color:'#FF8D1A'},{label:'已领取/已转账',id:1,color:'#00BF4DFF'},
            {label:'停止发薪',id:5,color:'#FF2F00'},{label:'领取失败/转账失败',id:2,color:'#DB0000FF'}],
        //领取方式
        receiveWayList:[{label:'支付宝',id:1},{label:'微信',id:2}],
        //字典列表
        dictList:getDictList,

        //全局默认配置链接：
        //店铺轮播图默认图片链接
        shopBannerUrl:'https://testfile.youmijob.com/images/20241209/aadda02a-e034-497f-9842-95d22f11930e.png',
        //店铺功能位=>默认图片链接、默认名称
        shopFun:[
            {name:'日结工',url:'https://testfile.youmijob.com/images/20241209/97ab4401-9a0c-45cc-ad67-8c20ef3e18c0.png'},
            {name:'长期工',url:'https://testfile.youmijob.com/images/20241209/6711f690-83cf-484f-b3e3-19a8b363aa27.png'},
            {name:'周结工',url:'https://testfile.youmijob.com/images/20241209/911b68fa-9be5-488c-9f81-2924bf71eff7.png'},
            {name:'大龄工',url:'https://testfile.youmijob.com/images/20241205/c6f5a326-9a56-40b3-9640-dacb6efa7449.png'},
        ],
        // shopFunUrl1:'https://testfile.youmijob.com/images/20241209/97ab4401-9a0c-45cc-ad67-8c20ef3e18c0.png',
        // shopFunUrl2:'https://testfile.youmijob.com/images/20241209/6711f690-83cf-484f-b3e3-19a8b363aa27.png',
        // shopFunUrl3:'https://testfile.youmijob.com/images/20241209/911b68fa-9be5-488c-9f81-2924bf71eff7.png',
        // shopFunUrl4:'https://testfile.youmijob.com/images/20241205/c6f5a326-9a56-40b3-9640-dacb6efa7449.png',

    }),
    mutations: {
        //保存字典列表
        saveDictJson(state, dictJson) {
            state.dictList = !state.dictList&&dictJson?JSON.parse(dictJson):null;
            that.$localSet('YM_DICT', dictJson)
        },
    },
    actions: {

    },
    getters: {

    }
}
export default globalStore
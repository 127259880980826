import Router from 'vue-router'
import router from "../router"; //原路由对象
const routes = [
    // 默认
    {
        path: "/",
        redirect: "/index",
        meta: {
            title:'优米',
        },
    },
    //公共首页
    {
        path: "/index",
        name: "index",
        component: () => import("../views/defaultHome"),
        meta:{
            title:'优米前程',
        }
    },
    //登录页
    {
        path: "/login",
        name: "login",
        component: () => import("../views/login"),
        meta:{
            title:'优米前程-登录',
        }
    },
    {
        path:'/used',
        name:'常用工具',
        component:()=>import('../views/used/used'),
        redirect:'/used/team',
        meta:{
            icon:"el-icon-menu",
            title:'常用工具',
            name:'常用工具',
            redirect:'/used/team',
        },
        children:[
            // {
            //     path:'/used/addressMng',
            //     name:'常用工具-地址管理',
            //     component:()=>import('../views/used/address/addressMng'),
            //     meta:{
            //         icon:"el-icon-menu",
            //         title: "常用工具-地址管理",
            //         name: "地址管理",
            //         flag: false
            //     },
            //     children:[]
            // },
            {
                path:'/used/team',
                name:'常用工具-团队管理',
                redirect: '/used/team/member',
                component:()=>import('../views/used/team/teamMng'),
                meta:{
                    icon:"el-icon-menu",
                    title: "常用工具-团队管理",
                    redirect: '/used/team/member',
                    name: "团队管理",
                    flag: true
                },
                children:[
                    {
                        path:'/used/team/member',
                        name:'常用工具-成员管理',
                        component:()=>import('../views/used/team/member/memberMng'),
                        meta:{
                            icon:"el-icon-menu",
                            title: "常用工具-成员管理",
                            name: "成员管理",
                            flag: false
                        },
                        children:[]
                    }
                ]
            }
        ]
    },
    //404
    {
        path: '*',
        name:'404',
        component:()=>import('../views/notFount'),
        meta: {
            title: '404'
        }
    }
];
const createRouter = ()=> new Router({
    mode: 'history',
    routes
})

export function resetRouter () {
    // 用初始化的matcher替换当前router的matcher
    const newRouter = createRouter()
    router.matcher = newRouter.matcher
}

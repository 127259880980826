//状态数据公共管理插件
export  default {
    //充值记录-状态过滤 ==》api:/eu/salary/service/recharge/list
    rechargeStatus(state){
        state = Number(state)
        switch (state) {
            case 0:
                return '未审核'
                break;
            case 1:
                return '已通过'
                break;
            case 2:
                return '已驳回'
                break;
            default:
                return  '--'
        }
    },
}

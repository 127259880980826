<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>
<script>
export default {
  name: "App",
};
</script>
<style lang="scss">
@import "style/public";
@import "style/fonts/youshe/YouSheBiaoTiHei.css";
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .el-header {
    border-bottom: 1px solid #e6e6e6 !important;
    padding: 0 20px 0 0 !important;
  }

  .el-menu {
    height: 100%;
  }

  .el-menu-item-group > ul {
    display: flex;
    flex-wrap: wrap;
  }

  .el-submenu .el-menu-item {
    width: 100% !important;
    font-size: 14px;
    text-align: left;
    padding-left: 50px !important;
  }

  .el-menu-item:focus,
  .el-menu-item:hover {
    background-color: #fff !important;
  }

  .el-card__body, .el-main {
    padding: 0;
  }

  .is-active {
    color: #031ef3 !important;
    font-weight: 900;
  }

  .is-active .el-submenu__title {
    color: #031ef3 !important;
    font-weight: 900;
  }

  .is-active .el-submenu__title i {
    color: #031ef3;
    font-weight: 900;
  }
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

//E confirm样式
.ptClass {
  padding: 0 10px;
  padding-bottom: 20px !important;

  .el-message-box__title {
    align-items: center;
    justify-content: flex-start !important;

    span {
      font-weight: 500;
    }
  }

  .el-message-box__status.el-icon-error {
    color: rgba(255, 87, 51, 1);
  }

  .el-message-box__message {
    text-align: left;
    padding-left: 18px;
  }

  .el-message-box__btns {
    margin-top: 5px;
  }

  .cancelButtonClass {
    border-radius: 5px !important;
    background: linear-gradient(90deg, rgba(173, 211, 255, 0.67) 0%, rgba(255, 255, 255, 0) 100%) !important;
    border: 1px solid rgba(31, 120, 255, 1) !important;
    font-size: 12px;
    font-weight: 500;
    color: rgba(31, 120, 255, 1);
    padding: 10px 30px;
  }

  .confirmButtonClass {
    border-radius: 5px !important;
    background: rgba(50, 135, 255, 1) !important;
    border: 1px solid rgba(31, 120, 255, 1) !important;
    font-size: 12px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    padding: 10px 30px;
    margin-left: 20px !important;
  }

  .confirmButtonClass:hover {
    background: #409EFF !important;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
}
</style>

import Vue from 'vue'
import router from "@/router";
import {Loading, MessageBox} from "element-ui";
import "./global.func.js";
//全局切换二维码域名
Vue.prototype.$getQrBaseUrl=()=>{
    return process.env.VUE_APP_QR_URL
}
//防抖函数
Vue.prototype.$debounce = (fn,delay=500) =>{
    let timer = null;
    return function(...args){
        if(timer) clearTimeout(timer)
        timer = setTimeout(()=>{
            fn.apply(this,args)
        },delay)
    }
}
//节流函数
Vue.prototype.$throttle = (fn,delay=500)=>{
    let timer,lastTime = null;
    return function(...args){
        let nowTime = Date.now()
        if(timer&&nowTime<lastTime+delay){
            clearTimeout(timer)
            timer = setTimeout(()=>{
                lastTime = nowTime
                fn.apply(this,args)
            },delay)
        }else{
            lastTime = nowTime
            fn.apply(this,args)
        }

    }
}
//全局状态颜色使用
Vue.prototype.$color=(num)=>{
    num=Number(num)
    switch (num) {
        case 3:
            return '#C0C4CC'//置灰
        case 2:
            return 'rgba(255, 141, 26, 1)'//已驳回
            break;
        case 1:
            return 'rgba(67, 207, 124, 1)'//已审核
            break;
        case 0:
            return 'rgba(255, 28, 28, 1)'//待审核
            break;
        default:return 'rgba(67, 207, 124, 1)'//1 已审核
    }
}
//二次封装element 开启遮罩
Vue.prototype.$ELoading =(num,text='',spinner)=>{
    Loading.service({
        lock: true,
        fullscreen: true,
        text,
        spinner:spinner||'el-icon-loading',
        background: `rgba(0, 0, 0, ${num?num:0.5})`
    });
}
//二次封装element 关闭遮罩
Vue.prototype.$EClose =()=>{
    Loading.service({}).close();
}
//二次封装element 删除警告弹窗  '确认删除后无法恢复，确认删除请点击确定即可删除xx。', '确认要删除此xx吗？'
Vue.prototype.$EConfirm=(message, title, type,flag=true)=>{
    if(flag===false){
      return new Promise(resolve => {
            resolve()
        })
    }
    return MessageBox.confirm(message||'确认删除后无法恢复，确认删除请点击确定即可删除该条数据。', title||'确认删除该条数据吗？', {
        cancelButtonClass:'cancelButtonClass',
        confirmButtonClass:'confirmButtonClass',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showClose:false,
        type: type||'error',
        customClass:'ptClass',
        center:true
    })
}
//请求参数空值过滤处理方法
Vue.prototype.$req=(data)=>{
    const req = JSON.parse(JSON.stringify(data)) //防止指针指向相同空间
    for (let reqKey in req) {
        if(req[reqKey]===''||req[reqKey]===null||req[reqKey]===undefined||JSON.stringify(req[reqKey])=='{}'||JSON.stringify(req[reqKey])=='[]'){
            delete req[reqKey]
        }else{
            if(req[reqKey] instanceof Object){
                for (const reqKeyKey in req[reqKey]) {
                    if(req[reqKey][reqKeyKey]===''||req[reqKey][reqKeyKey]===null||req[reqKey][reqKeyKey]===undefined||req[reqKey][reqKeyKey].length==0){
                        delete req[reqKey][reqKeyKey]
                    }
                    // if(JSON.stringify(req[reqKey])=='{}'){
                    //   delete req[reqKey]
                    // }
                }
            }
        }
    }
    return req
}
//本地永久缓存存储方法 // 不进行json序列化
Vue.prototype.$localSet=(key,data)=>{
    localStorage.setItem(key,data)
    return 'localStorage存储成功'
}
//本地永久缓存读取方法
Vue.prototype.$localGet=(key,data)=>{
    return JSON.parse(localStorage.getItem(key))
    return 'localStorage读取成功'
}
//本地临时缓存存储方法
Vue.prototype.$sessionSet=(data,key)=>{
    sessionStorage.setItem(key||'YM_Query',JSON.stringify(data))
    return 'sessionStorage存储成功'
}
//本地临时缓存读取方法
Vue.prototype.$sessionGet=(key)=>{
    return JSON.parse(sessionStorage.getItem(key||'YM_Query'))
}
//清除本地临时缓存方法
Vue.prototype.$sessionRemove=(key)=>{
    return sessionStorage.removeItem(key||'YM_Query')
}
//用户信息读取 //没有传值默认读取paasuserManager
Vue.prototype.$userGet=(key)=>{
    try{
        if(key){
            return JSON.parse(localStorage.getItem('YM_INFO'))?.[key]
        }
        return JSON.parse(localStorage.getItem('YM_INFO'))?.paasuserManager
    }catch (e){
        console.error('YM_INFO JSON格式错误')
        return {}
    }
    // return localStorage.getItem('YM_INFO')&&JSON.parse(localStorage.getItem('YM_INFO')).userLogin?
    //     JSON.parse(localStorage.getItem('YM_INFO')).userLogin:JSON.parse(localStorage.getItem('YM_INFO'))?.paasuserManager
}
/**
 * @description 路由跳转标签页缓存
 * @param path 路由地址
 * @param tag 来源标签页名称
 * @param to 跳转目标标签页名称
 */
Vue.prototype.$routerSetTag=(path,tag,to)=>{
    if(!path)return
    router.push({path,query:{tag,to}})
}
//路由返回父级标签页
Vue.prototype.$routerReturnTag=(path)=>{
    if(!path)return
    return {path,query:{tag:router.history.current.query.tag}}
}
//路由跳转标签页缓存读取
Vue.prototype.$routerGetTag=(tag)=>{
    if(router.history.current.query&&router.history.current.query.tag){
        tag=router.history.current.query.tag
    }
    return tag
}
//路由历史删除
//ed=false 不触发activated hook
Vue.prototype.$routeReplaceTag=(path,ed=true)=>{
    if(!path)return
    router.replace({path, query: {ed}})
}
Vue.prototype.$routeReplaceTrue=()=>{
    return router.app.$route.query.ed==='true'||router.app.$route.query.ed===true
}
//默认头像
Vue.prototype.$defaultHeadUrl=()=>{
    return 'https://testfile.youmijob.com/images/20231024/f485a0908eac2d68101ab23d0f70a0b.png'
}
//全局timeout防抖控制对象
Vue.prototype.$timeout =null
//全局timeout防抖控制函数
Vue.prototype.$timeoutHandle =(fun)=>{
    if(Vue.prototype.$timeout)return
    fun()
    Vue.prototype.$timeout=setTimeout(()=>{
        Vue.prototype.$timeout=null
    },600)
}
//判断非空取数字方法
Vue.prototype.$ifNum=(data,length=0,uni='',defStr='--')=>{
   if(data!==''&&data!==undefined&&data!==null&&String(Number(data))!== 'NaN'){
       if(!uni){
           return Number(data)?.toFixed(length)
       }
       return length?Number(data)?.toFixed(length)+uni:data+uni
   }else{
       return defStr
   }
}

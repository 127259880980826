import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import iApi from '../util/HFApi'
Vue.use(VueRouter);
import store from '@/store/index.js'
const RouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (to) {
  return RouterPush.call(this, to).catch(err => err)
}
const RouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (to) {
  return RouterReplace.call(this, to).catch(err => err)
}
import routerJson from './index.json'
import {Message} from "element-ui";
let arr = []
const routes = [
  // 默认
  {
    path: "/",
    redirect: "/index",
    meta: {
      title:'优米',
    },
  },
    //公共首页
  {
    path: "/index",
    name: "index",
    component: () => import("../views/defaultHome"),
    meta:{
      title:'优米前程',
    }
  },
  //登录页
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login"),
    meta:{
      title:'优米前程-登录',
    }
  },
  {
    path:'/used',
    name:'常用工具',
    component:()=>import('../views/used/used'),
    redirect:'/used/addressMng',
    meta:{
      icon:"el-icon-menu",
      title:'常用工具',
      name:'常用工具',
      redirect:'/used/addressMng',
    },
    children:[
      // {
      //   path:'/used/addressMng',
      //   name:'常用工具-地址管理',
      //   component:()=>import('../views/used/address/addressMng'),
      //   meta:{
      //     icon:"el-icon-menu",
      //     title: "常用工具-地址管理",
      //     name: "地址管理",
      //     flag: false
      //   },
      //   children:[]
      // },
      {
        path:'/used/team',
        name:'常用工具-团队管理',
        redirect: '/used/team/member',
        component:()=>import('../views/used/team/teamMng'),
        meta:{
          redirect: '/used/team/member',
          icon:"el-icon-menu",
          title: "常用工具-团队管理",
          name: "团队管理",
          flag: true
        },
        children:[
          {
            path:'/used/team/member',
            name:'常用工具-成员管理',
            component:()=>import('../views/used/team/member/memberMng'),
            meta:{
              icon:"el-icon-menu",
              title: "常用工具-成员管理",
              name: "成员管理",
              flag: false
            },
            children:[]
          }
        ]
      }
    ]
  },
    //404
  {
    path: '*',
    name:'404',
    component:()=>import('../views/notFount'),
    meta: {
      title: '404'
    }
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
let THREE =[] //所有功能树
let USER_THREE = [] //用户权限树
if(localStorage.getItem('YM_THREE')){
  THREE = JSON.parse(localStorage.getItem('YM_THREE'))
}
if(localStorage.getItem('YM_USER_THREE')){
  USER_THREE = JSON.parse(localStorage.getItem('YM_USER_THREE'))
}
//动态添加路由
if(USER_THREE.length){
    const data = JSON.parse(localStorage.getItem('YM_USER_THREE'))
    const arr = iApi.getRouter(data)
    if(arr&&arr.length){
      arr.forEach(item=>{
        router.addRoute(item.name,item)
      })
      localStorage.setItem('YM_USER_THREE_HANDLE','1')//代表已经处理过权限树json数据 json字符串数据转换为json对象数据
    }else {
      Message.error('账户当前无权限')
    }
}
//所有功能数
if(THREE.length){
  if(!localStorage.getItem('YM_THREE_HANDLE')){
    const data = JSON.parse(localStorage.getItem('YM_THREE'))
    const arr = iApi.getRouter(data)
    localStorage.setItem('YM_THREE_HANDLE',JSON.stringify(arr))
  }
}
const whiteList=['/login']
//路由守卫
router.beforeEach((to, from, next) => {
  //出现进度条
  NProgress.start()
  if(whiteList.includes(to.path)){
    next()
  }else{
    if(sessionStorage.getItem('YM_TOKEN')){
      //非超管无法进入团队管理
      if(to.fullPath.includes('/used/team/member')&&JSON.parse(localStorage.getItem('YM_INFO'))?.type!=='1'){
        store.commit('authorDetermine',{flag:true,txt:to?.meta?.name||to.name})
        next('/login')
      }else{
        next()
      }
    }else{
      next('/login')
    }
  }
  // //登录时可能需要的一个操作赋值
  // next()
})

router.afterEach((to,from)=>{
  document.title=to.meta.title
  NProgress.done()
})
export default router;
